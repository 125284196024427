import request from '../utils/request'
import baseURL from '../utils/config'

//物流订单-订单列表查询
export function logorderggetlist(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/GetList',
      method: 'post',
      data
  })
}
//物流订单-导出
export function Export(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Order/Export',
        method: 'post',
        data
    })
}
//物流订单-得到订单状态下拉列表
export function OrderState() {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/Drop_OrderState',
      method: 'get',
  })
}
//物流订单-得到快递公司下拉列表
export function courier() {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/Drop_LogisticsCompany',
      method: 'get',
  })
}
// 物流订单-得到所属平台列表
export function service(params) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/Drop_SourceService',
      method: 'get',
      params: params
  })
}
// GET 物流订单-得到详情
export function Detail(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/GetDetail',
      method: 'get',
      params:data
  })
}

// GET 物流订单-得到详情-直发
export function GetDetail_ZF(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Order/GetDetail_ZF',
        method: 'get',
        params: data
    })
}

// GET 物流订单-得到详情-商城
export function GetDetail_SC(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_Order/GetDetail_SC',
        method: 'get',
        params: data
    })
}
//  发货
export function SendOutGoods(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/SendOutGoods',
      method: 'post',
      data
  })
}
// 取消发货
export function CancelOrder(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_Order/CancelOrder',
      method: 'post',
      data
  })
}
//物流公司 估价
export function EstimatePriceRange(data) {
  return request({
      url: baseURL.baseURL + 'api/Synthetical/EstimatePriceRange',
      method: 'post',
      data
  })
}
// 获取收发件人列表
export function personList(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_PersonalAddress/GetList',
      method: 'get',
      params:data
  })
}

//物流订单-得到商铺下拉列表
export function Drop_ShopInfo() {
    return request({
        url: baseURL.baseURL + 'api/Adm_Order/Drop_ShopInfo',
        method: 'get',
    })
}
