<template>
  <div class="detail-continer">
    <div class="detail-center">
      <div class="center-order">
        <el-descriptions title="订单信息" size="mini" :column="2" border>
          <el-descriptions-item
            label="订单编号"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.orderNo }}</el-descriptions-item
          >
          <el-descriptions-item
            label="订单来源"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.sourceServiceName }}</el-descriptions-item
          >
          <el-descriptions-item
            label="收货人姓名"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.receiverName }}</el-descriptions-item
          >
          <el-descriptions-item
            label="收货人电话"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.receiverMobile }}</el-descriptions-item
          >
          <el-descriptions-item
            label="收货人地址"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.receiverAddress }}</el-descriptions-item
          >
          <el-descriptions-item
            label="收货时间"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.receiverTime }}</el-descriptions-item
          >
          <el-descriptions-item
            label="收货人备注"
            label-class-name="my-label"
            content-class-name="my-content"
            >配送前电话联系</el-descriptions-item
          >
            <el-descriptions-item
            label="用户账号"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.userName }}</el-descriptions-item
          >
          <el-descriptions-item
            label="发货人（公司）姓名"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.senderName }}</el-descriptions-item
          >
          <el-descriptions-item
            label="发货所属公司"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.senderCompanyName }}</el-descriptions-item
          >
          <el-descriptions-item
            label="联系人电话"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.senderMobile }}</el-descriptions-item
          >
          <el-descriptions-item
            label="发货地址"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.senderAddress }}</el-descriptions-item
          >
          <el-descriptions-item
            label="平台合作方"
            label-class-name="my-label"
            content-class-name="my-content"
            >{{ orderInfo.cooperateCompanyName }}</el-descriptions-item
          >
        </el-descriptions>
      </div>
      <!-- <div class="center-parcel">
        <div class="parcel-head">包裹信息</div>
        <el-table :data="packageInfoList" border style="width: 100%">
          <el-table-column prop="index" label="序号" width="180">
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称" width="500">
          </el-table-column>
          <el-table-column prop="goodsId" label="商品id"> </el-table-column>
          <el-table-column prop="num" label="商品数量"> </el-table-column>
        </el-table>
        <div class="parcel-end">
          <div class="end-left">合计：</div>
          <div class="end-right">2</div>
        </div>
      </div> -->
      <div class="center-parcel">
        <div class="parcel-head">物流信息</div>
        <el-table
          :data="logisticsInfo"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#d0e0f7' }"
        >
          <el-table-column
            prop="date"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="wayBillNo" label="物流单号"> </el-table-column>
          <el-table-column prop="expressName" label="物流品牌">
          </el-table-column>
          <el-table-column prop="freight" label="物流费用（单价元）">
          </el-table-column>
          <el-table-column prop="isInsuredPrice" label="是否保价">
          </el-table-column>
          <el-table-column prop="insuredPriceAmount" label="保价费用（单价元）">
          </el-table-column>
          <el-table-column prop="payMethod" label="结算方式"> </el-table-column>
          <el-table-column prop="timeSendOutGoods" label="发货时间">
          </el-table-column>
          <el-table-column prop="timePickup" label="揽件时间">
          </el-table-column>
          <el-table-column prop="stateName" label="物流状态"> </el-table-column>
          <el-table-column prop="handler" label="发货经办人"> </el-table-column>
        </el-table>
      </div>
      <!-- <div class="center-step">
        <div>
          <el-steps direction="vertical" >
            <el-step
            v-for="item in trajectory"
            :key="item.trackTime"
              :title="item.trackTime"
              :description="item.trackRemark"
            ></el-step>
          </el-steps>
        </div>
      </div> -->
      <div class="center-parcel">
        <div class="parcel-head">结算及支付</div>
        <el-table
          :data="payAndSettlement"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#d0e0f7' }"
        >
          <el-table-column
            prop="freight"
            label="物流费用（单价元）"
            width="120"
          ></el-table-column>
          <el-table-column prop="couponAmount" label="优惠费用（单位元）">
          </el-table-column>
          <el-table-column prop="insuredPriceAmount" label="保价费用（单价元）">
          </el-table-column>
          <el-table-column prop="shouldPayAmount" label="应付费用（单位元）">
          </el-table-column>
          <el-table-column prop="payPassageway" label="支付通道">
          </el-table-column>
          <el-table-column prop="payProceduresRate" label="支付手续费率">
          </el-table-column>
          <el-table-column
            prop="payProceduresAmount"
            label="支付手续费（单位元）"
          >
          </el-table-column>
          <el-table-column prop="settlementAmount" label="结算费用（单位元）">
          </el-table-column>
          <el-table-column prop="settlementTime" label="结算时间">
          </el-table-column>
          <el-table-column prop="settlementState" label="结算状态">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { GetDetail_ZF } from "../../api/logorder";
export default {
  data() {
    return {
      a: 111,
      id: "", //运单号
      orderInfo: {}, //订单信息
      logisticsInfo: [], //物流信息
      trajectory: [], //物流进度
      payAndSettlement: [], //支付及结算
      packageInfoList: [], //包裹信息
    };
  },
  methods: {
    getDetail() {
      GetDetail_ZF({ id: this.id })
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.orderInfo = res.data.orderInfo; //订单信息
            this.logisticsInfo = res.data.logisticsInfo; //物流信息
            this.trajectory = res.data.trajectory; //物流进度
            this.trajectory.reverse();
            console.log(this.trajectory);
            this.payAndSettlement = res.data.payAndSettlement; //支付及结算
            this.packageInfoList = res.data.packageInfoList; //包裹信息
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
};
</script>

<style scoped>
.detail-continer {
  width: 100%;
  /* height: 500px; */
  background: #dddddd;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
}
.detail-center {
  width: 100%;
  /* height: 100%; */
  background: white;
  border-radius: 6px;
  padding: 10px 5px 10px 5px;
  box-sizing: border-box;
}
/deep/ .my-label {
  background: #d0e0f7;
}
.my-content {
  background: #d0e0f7;
}
.center-parcel {
  margin-top: 30px;
}
.parcel-head {
  /* font-size: 13px; */
  font-weight: 700;
  margin-bottom: 15px;
}
.parcel-end {
  width: 100%;
  height: 40px;
  border: 1px solid #dddddd;
}
.end-left {
  width: 84%;
  height: 100%;
  border-right: 1px solid #dddddd;
  line-height: 40px;
  float: left;
  text-align: right;
}
.end-right {
  width: 15%;
  height: 100%;
  line-height: 40px;
  float: right;
}
.center-step {
  width: 100%;
  /* height: 500px; */
  border: 1px solid #dddddd;
  margin-top: 30px;
}
</style>
